<template>
  <div class="deleted-doc-types top-gap-lg">
    <TableActions
      type="deleted"
      :number-of-selected="selected.length"
      :lock-actions="selected.some(item => item.is_locked)"
      :create-condition="false"
      :delete-condition="false"
      @reinstate-click="handleReinstateButton"
      @filter-change="(filter) => trimmedFilter = filter"
    >
      <template #actions>
        <TableAction
          v-if="selected.length > 0"
          prepend-icon="fas fa-history"
          :label="$tc('recycleBin.restore', { number: selected.length })"
          @click="handleReinstateButton"
        />
        <TableAction
          v-if="selected.length > 0"
          class="ml-4"
          prepend-icon="fas fa-trash"
          :label="$tc('datatable.header.delete', { number: selected.length })"
          @click="deleteDialog = true"
        />
        <TableAction
          v-if="paginated.length > 0 && selected.length === 0"
          button-variant="outlined"
          prepend-icon="fas fa-exclamation-triangle"
          :label="$t('deleted.delete_all')"
          @click="deleteAllDialog = true"
          show-text
        />
      </template>
    </TableActions>
    <TableWithFooter
      :loading="loading"
      :paginated-items-length="paginated.length"
      :total="total"
      :current-page="currentPage"
      :items-per-page="itemsPerPage"
      @change-items-per-page="(_itemsPerPage) => itemsPerPage = _itemsPerPage"
      @change-page="(page) => currentPage = page"
    >
      <template #header>
        <v-col cols="auto">
          <SortButton v-model="sortDesc" />
          <v-checkbox
            v-model="allSelected"
            class="mt-0"
            @change="toggleSelectAll"
            hide-details
          />
        </v-col>
        <v-col cols="6">
          {{ $t('docTypes.doc_type') }}
        </v-col>
        <v-col cols="5">
          {{ $t("recycleBin.removed_on") }}
        </v-col>
      </template>
      <template #body>
        <v-container
          class="pa-0"
          fluid
        >
          <v-row
            v-for="item in paginated"
            :key="item.id"
            class="table-row fade-in table-row-height"
          >
            <v-col cols="auto">
              <v-checkbox
                v-model="item.selected"
                class="left-gap mt-0"
                @change="handleSelect"
                hide-details
              />
            </v-col>
            <v-col cols="6">
              <ItemName
                :key="item.id"
                :item="item"
                :clickable="false"
              />
            </v-col>
            <v-col
              v-if="config"
              cols="5"
            >
              {{
                $tc(
                  'n_days',
                  getRemovedInDays(item.deleted_on, config.delete_retention_days),
                  { count: getRemovedInDays(item.deleted_on, config.delete_retention_days) }
                )
              }}
            </v-col>
            <v-col
              v-else
              cols="5"
            />
          </v-row>
        </v-container>
      </template>
    </TableWithFooter>
    <DeleteDialog
      v-model="deleteDialog"
      :title="$t('recycleBin.delete_doctype_title')"
      :message="$t('recycleBin.delete_doctype_confirm')"
      @confirm="finalDeleteDoctype"
      @close="deleteDialog = false"
    />
    <DeleteDialog
      v-model="deleteAllDialog"
      :title="$t('recycleBin.delete_doctype_all_title')"
      :message="$t('recycleBin.delete_doctype_all_confirm')"
      @confirm="finalDeleteAllDoctype"
      @close="deleteAllDialog = false"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import { http } from '@/plugins/axios';
import { DocTypeAPI } from '@/API/extract/DocTypeAPI';
import { getRemovedInDays } from '@/utils/DateUtils';
import table_mixin from '@/mixins/table.js';
import format_mixin from '@/mixins/format.js';
import SortButton from '@/components/common/elements/Tables/SortButton';
import ItemName from '@/components/common/elements/General/ItemName';
import TableWithFooter from '@/components/common/elements/Tables/TableWithFooter';
import { useTableWithFooter } from '@/composables/useTableWithFooter.js';
import TableActions from '@/components/common/elements/Tables/TableActions';
import TableAction from '@/components/common/elements/Tables/TableAction';
import DeleteDialog from "@/components/common/elements/Tables/DeleteDialog";

export default {
  name: 'DeletedDocTypes',

  mixins: [table_mixin, format_mixin],

  components: {
    TableActions,
    TableAction,
    TableWithFooter,
    ItemName,
    DeleteDialog,
    SortButton,
  },

  data() {
    const { itemsPerPage, currentPage } = useTableWithFooter(
      `${this.$route.path}_${this.$options.name}`);

    return {
      sortDesc: true,
      deleteAllDialog: false,
      itemsPerPage,
      currentPage,
    };
  },

  computed: {
  },

  watch: {
    sortDesc() {
      this.getDeletedDoctypes();
    },

    total(total) {
      if (this.trimmedFilter === '') {
        this.$emit('changeTotal', total);
      }
    },

    itemsPerPage() {
      this.resetCurrentPage();
      this.getDeletedDoctypes();
    },

    currentPage(page) {
      this.handleCurrentPageChange(page);
    },

    trimmedFilter: _.debounce(
      function() {
        this.resetCurrentPage();
        this.getDeletedDoctypes();
      }, 300
    ),
  },

  async created() {
    await this.getDeletedDoctypes();
  },

  methods: {
    getRemovedInDays,

    async handleReinstateButton() {
      await Promise.all(this.selected.map(async type => {
        try {
          return await http.put(`system_2/document_type/restore/${type.id}/`);
        } catch (error) {
          return
        }
      }));
      await this.$store.commit(
        'setSuccessMessage', this.$t('docTypes.restored_message')
      );
      this.$store.commit('setSuccessSnackbar', true);
      this.finishDeletion();
    },

    handleCurrentPageChange(page) {
      this.allSelected = false;
      this.paginated.forEach(item => {
        item.selected = this.allSelected;
      });
      this.forceCompute = Math.random();
      this.getDeletedDoctypes((page - 1) * this.itemsPerPage, this.itemsPerPage);
    },

    async getDeletedDoctypes(
      offset = (this.currentPage - 1) * this.itemsPerPage,
      limit = this.itemsPerPage)
    {
      try {
        this.loading = true;
        const response = await DocTypeAPI.get(
          limit,
          offset,
          this.trimmedFilter || '',
          'deleted',
          null,
          null,
          null,
          this.sortDesc,
        );
        this.paginated = response.data;
        this.total = parseInt(response.headers['x-total-count'], 10);
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.loading = false;
      }
    },

    async finalDeleteAllDoctype() {
      try {
        await http.delete(`system_2/document_type/empty_bin/`);
        this.paginated = [];
        await this.$store.commit(
          'setSuccessMessage', this.$t('docTypes.deleted_message')
        );
        this.$store.commit('setSuccessSnackbar', true);
        this.$emit('changeTotal', 0);
      } catch (error) {
        if (!this.paginated.indexOf(item => error.response.data.locked_doctypes.includes(item.id))) {
          await this.getDeletedDoctypes();
        }
      } finally {
        this.currentPage = 1;
        this.allSelected = false;
        this.deleteAllDialog = false;
      }
    },

    async finalDeleteDoctype() {
      await Promise.all(this.selected.map(async type => {
        try {
          return await http.delete(`system_2/document_type/${type.id}/?soft=false`);
        } catch (error) {
          return null
        }
      }));
      this.finishDeletion();
    },

    async finishDeletion() {
      const { currentPage, itemsPerPage} = this;
      await this.getDeletedDoctypes();
      const lastPage = Math.max(1, Math.ceil(this.total / itemsPerPage));
      this.currentPage = Math.min(currentPage, lastPage);
      this.allSelected = false;
      this.handleCurrentPageChange(this.currentPage);
      this.deleteDialog = false;
    },
  },

  props: {
    config: {
      type: Object,
      required: true,
    },
  },

  emits: ['changeTotal'],
}
</script>
