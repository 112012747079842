<template>
  <div class="extraction-group-config">
    <div
      class="top-gap bottom-gap-sm"
      style="max-width: 83%;"
    >
      <div class="label mb-2">
        {{ $t('dataPoints.description') }}
      </div>
      <TextEditor
        ref="descriptionEditor"
        class="w-100"
        rows="2"
        :text="extractorDescription"
        @changed="(description) => $emit('updateDescription', description)"
        @save="(description) => $emit('updateDescription', description)"
      />
    </div>
    <div class="label">
      {{ $t('dataPoints.extract_method') }}
    </div>
    <v-radio-group
      v-model="extractionType"
      class="top-gap-sm"
      hide-details
      inline
    >
      <div class="inline-middle radio-box right-gap-sm bottom-gap-sm">
        <v-radio
          value="model"
          :label="$t('dataPoints.model_option')"
        />
      </div>
      <div class="inline-middle radio-box right-gap-sm bottom-gap-sm">
        <v-radio
          value="generative"
          :label="$t('dataPoints.generative.title')"
        />
      </div>
    </v-radio-group>
    <GenerativeWarning
      v-if="extractionType === 'generative'"
      class="mb-6"
    />
    <div class="label">
      {{ $t('menu.configuration_review') }}
    </div>
    <div
      class="inline-middle radio-box right-gap-sm bottom-gap-sm"
      style="padding-right: 20px; margin-top: 5px;"
    >
      <v-checkbox
        v-model="group.table_display"
        class="inline-middle right-gap-sm"
        style="margin-top: -8px"
        color="primary"
        :label="$t('corrections.table_display')"
        @update:model-value="$emit('updated')"
      />
    </div>
    <div v-if="extractionType === 'model'">
      <div class="label">
        {{ $t('dataPoints.aggregation_method') }}
      </div>
      <v-select 
        v-model="group.aggregation_method" 
        class="inline-middle"
        style="margin-top: 5px; width: 300px;"
        variant="outlined"
        color="primary"
        density="compact"
        :items="localMethods"
        @update:model-value="handleMethodChange"
      />
      <div v-if="type === 'extraction_group' && group.aggregation_method === 'block'">
        <div class="top-gap-sm">
          <div
            class="inline-middle radio-box right-gap-sm"
            style="padding-right: 20px"
          >
            <v-checkbox
              v-model="group.multi_page"
              class="inline-middle right-gap-sm"
              style="margin-top: -7px"
              color="primary"
              :label="$t('dataPoints.multi_page')"
              @update:model-value="handleMultiPage"
            />
          </div>
        </div>
      </div>
      <div v-if="type === 'extraction_group' && group.aggregation_method === 'cluster'">
        <div class="label top-gap-sm">
          {{ $t('dataPoints.number_of_clusters') }}
        </div>
        <div class="top-gap-sm">
          <div
            class="inline-middle radio-box right-gap-sm"
            style="padding-right: 20px"
          >
            <v-checkbox
              v-model="autoDetectClusters"
              class="inline-middle right-gap-sm"
              style="margin-top: -7px"
              color="primary"
              :label="$t('dataPoints.auto_detect')"
            />
          </div>
          <div
            class="inline-middle radio-box"
            style="padding-right: 10px"
            :style="{ opacity: autoDetectClusters ? 0.5 : 1 }"
          >
            <input
              ref="PageField"
              v-model="group.nb_clusters"
              class="config-input-field right-gap-sm accent--text"
              style="margin-top: 5px; margin-left: 10px;"
              placeholder="0"
              type="number"
              :min="0"
              @input="$emit('updated')"
            >
          </div>
        </div>
      </div>
      <div
        v-if="type == 'extraction_group'"
        class="top-gap-sm"
      >
        <div
          class="radio-box inline-middle"
          style="padding-right: 20px"
        >
          <v-checkbox
            v-model="keepBestOn"
            class="inline-middle right-gap-sm"
            style="margin-top: -7px"
            color="primary"
            :label="$t('dataPoints.keep_values_from')"
            @update:model-value="$emit('updated')"
          />
          <input
            ref="PageField"
            v-model="group.keep_best"
            class="config-input-field right-gap-sm primary--text"
            style="position: relative; top: -14px"
            placeholder="1"
            type="number"
            :min="1"
            @input="handleKeepBestInput"
          >
          <div
            class="inline-middle clickable"
            style="font-size: 0.9rem; margin-top: -30px;"
            @click="keepBestOn = !keepBestOn"
          >
            {{ $t('dataPoints.best_pages') }}
          </div>
        </div>
        <div
          class="left-gap-lg top-gap-sm"
          style="width: fit-content"
        >
          <div
            class="radio-box inline-top right-gap-sm"
            style="padding-right: 10px"
          >
            <v-checkbox
              v-model="hasForbiddenValues"
              class="inline-middle right-gap-sm"
              style="margin-top: -7px"
              color="primary"
              :label="$t('dataPoints.forbidden_values')"
              :class="{ 'disabled-text': !keepBestOn }"
              :disabled="!keepBestOn"
              @update:model-value="$emit('updated')"
            />
          </div>
          <div
            style="text-align: right"
            class="top-gap"
          >
            <div class="inline-top">
              <v-text-field
                v-for="(text, index) in group.forbidden_values"
                :key="index"
                :ref="`forbiddenValue-${index}`"
                v-model="group.forbidden_values[index]"
                class="word-input"
                style="width: 250px"
                append-icon="fas fa-times"
                variant="outlined"
                color="primary"
                density="compact"
                :disabled="!hasForbiddenValues"
                @click:append="removeExcluded(text)"
                @keyup.enter="({ target }) => target.blur()"
                @update:model-value="$emit('updated')"
              />
              <v-text-field
                ref="sameLineField"
                v-model="newExcluded"
                class="word-input"
                style="width: 250px"
                variant="outlined"
                color="primary"
                density="compact"
                :placeholder="$t('dataPoints.type_new_text')"
                :disabled="!hasForbiddenValues"
                @keyup.enter="handleNewExcluded"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-if="type == 'extraction_group'">
        <div
          v-for="(option, index) in switchOptions"
          :key="index"
          class="inline-middle radio-box top-gap-sm right-gap-sm"
          style="padding-right: 9px !important;"
        >
          <v-checkbox
            v-model="group[option.value]"
            class="inline-middle right-gap-sm"
            style="margin-top: -7px"
            color="primary"
            :class="{
              'disabled-text': option.value === 'single_item' && group.aggregation_method === 'cluster'
            }"
            :label="option.label"
            :disabled="option.value === 'single_item' && group.aggregation_method === 'cluster'"
            @update:model-value="option.onChange"
          />
          <v-tooltip
            v-if="option.value === 'show_empty_primary'"
            right
          >
            <template #activator="{ props }">
              <v-icon
                class="clickable"
                color="primary"
                style="margin-top: -30px"
                size="16"
                v-bind="props"
              >
                fas fa-info-circle
              </v-icon>
            </template>
            {{ option.info }}
          </v-tooltip>
        </div>
      </div>
      <div class="top-gap">
        <div class="label">
          {{ $t('models.labels') }}
          <v-chip
            class="ml-2"
            style="background-color: #CCC2FF !important"
            variant="outlined"
          >
            {{ group.labels.length }}
            {{ $tc('models.added_labels', group.labels.length == 1 ? 1 : 2) }}
          </v-chip>
        </div>
        <div
          v-if="group.labels.length > 0 && !group.primary_labels.some(item => item)"
          class="info-box top-gap"
        >
          <small>
            <div
              class="inline-middle"
              style="width: 30px"
            >
              <v-icon
                class="info-icon"
                size="16"
              >
                fas fa-info-circle
              </v-icon>
            </div>
            <div
              class="inline-middle"
              style="width: calc(100% - 30px)"
            >
              {{ $t('dataPoints.primary_info') }}
            </div>
          </small>
        </div>
        <div class="top-gap d-flex align-items-start">
          <ItemName
            class="model-name mr-2"
            :item="{ name: selectedName }"
            :clickable="false"
            :editing-allowed="false"
            :show-id="false"
            :badge="modelVersions.length === 1 && group.custom_model_version ? `v${group.custom_model_version}` : ''"
          />
          <div v-if="modelVersions.length > 1 && group.custom_model_id !== 0">
            <span class="mr-2 inline-top">
              v
            </span>
            <v-select
              v-model="group.custom_model_version"
              class="version-select clickable inline-middle"
              variant="outlined"
              color="primary"
              density="compact"
              item-title="version"
              item-value="version"
              :disabled="group.custom_model_id === 0"
              :items="modelVersions"
              :placeholder="$t('models.no_version_selected')"
              @update:model-value="handleVersionChange"
            />
          </div>
        </div>
        <v-card
          v-if="group.labels.length > 0"
          class="top-gap label-card"
        >
          <v-row class="label-row label-bottom font-weight-bold flex-nowrap">
            <v-col
              cols="5"
              class="d-flex align-center"
            >
              {{ $t('models.labels') }}
            </v-col>
            <v-col
              class="d-flex align-center"
              cols="4"
            >
              {{ $t('datatable.header.value_type') }}
            </v-col>
            <v-col
              class="d-flex flex-column align-center"
              cols="3"
            >
              <div style="margin-top: 5px; margin-bottom: -10px;">
                {{ $t('dataPoints.primary') }}
              </div>
              <v-switch
                v-model="allPrimary"
                class="primary-label-switch mt-0"
                color="primary"
                density="compact"
                :disabled="group.labels.length === 1 && group.labels[0].isPrimary"
                @change="togglePrimary"
                hide-details
              />
            </v-col>
          </v-row>
          <div
            v-for="(label, index) in group.labels"
            :key="label.name"
          >
            <v-row
              class="label-row flex-nowrap"
              :class="{
                'pb-0': label.valueType === 'regex',
                'label-bottom': !$options.constants.VALUE_TYPES_WITH_OPTIONS.includes(label.valueType),
              }"
            >
              <v-col
                cols="5"
                class="d-flex"
                style="height: 100%"
              >
                <ItemName
                  :clickable="false"
                  :item="{
                    name: label.displayName || label.name
                  }"
                  :editing="editName === label.name"
                  :show-edit-icon="type === 'extraction_group'"
                  :editing-allowed="type === 'extraction_group'"
                  :show-id="false"
                  :rename-label="$t('docTypes.display_name_info')"
                  :validator="(name) => noEmptyLabel(name) && noDuplicates(name, index)"
                  @save-file-name="(_, name) => saveDisplayName(name, index)"
                  @start-name-edit="editName = label.name"
                  @cancel-name-edit="editName = ''"
                />
                <v-icon
                  class="close-button clickable"
                  color="black"
                  size="14"
                  @click="deleteLabel(label.name)"
                >
                  fas fa-times
                </v-icon>
              </v-col>
              <v-col cols="4">
                <v-select
                  v-model="label.valueType"
                  variant="outlined"
                  color="primary"
                  density="compact"
                  :items="valueTypes"
                  :disabled="type === 'data_point'"
                  @update:model-value="handleValueTypeChange(label)"
                  hide-details
                />
              </v-col>
              <v-col
                cols="3"
                class="d-flex justify-center align-center"
              >
                <v-switch
                  v-model="label.isPrimary"
                  color="primary"
                  class="primary-label-switch inline-middle mt-2"
                  density="compact"
                  :disabled="group.labels.length === 1 && group.labels[0].isPrimary"
                  @update:model-value="checkPrimary(index)"
                  hide-details
                />
              </v-col>
            </v-row>
            <div
              v-if="$options.constants.VALUE_TYPES_WITH_OPTIONS.includes(label.valueType)"
              class="regex-config label-bottom px-7 py-2"
              :class="{ 'rounded-card rounded-t-0': index + 1 === group.labels.length }"
            >
              <v-row v-if="label.valueType === 'regex'">
                <v-col cols="5" />
                <v-col cols="4">
                  <div class="pb-4 pt-2">
                    Regex:
                    <input
                      ref="regexField"
                      v-model="label.regex.pattern"
                      class="regex-field right-gap-sm primary--text"
                      @keydown.enter="blurRegexField"
                    >
                    <div
                      class="inline-middle mt-2"
                      style="padding-right: 20px"
                    >
                      {{ $t('dataPoints.replace_match_with') }}
                      <input
                        ref="substituteField"
                        v-model="label.regex.substitute"
                        class="regex-field primary--text"
                        @keydown.enter="blurSubstituteField"
                      >
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-row
                v-if="label.valueType === 'unspecified'"
                style="margin-bottom: -33px"
              >
                <v-col cols="5" />
                <v-col cols="4">
                  <v-checkbox
                    v-model="label.returnBlock"
                    color="primary"
                    style="margin-left: -10px; margin-top: -5px"
                    class="right-gap-sm"
                    :label="$t('dataPoints.paragraph')"
                  />
                  <v-tooltip right>
                    <template #activator="{ props }">
                      <v-icon
                        class="clickable"
                        color="primary"
                        style="margin-top: -30px"
                        size="16"
                        v-bind="props"
                      >
                        fas fa-info-circle
                      </v-icon>
                    </template>
                    {{ $t('dataPoints.return_block') }}
                  </v-tooltip>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-card>
        <div
          v-else-if="labels.length === 0"
          class="no-label-sign mt-5"
        >
          {{ $t('dataPoints.no_labels') }}
        </div>
      </div>
      <div 
        v-if="duplicateName"
        class="fade-in error-message top-gap-sm"
      >
        {{ $t('dataPoints.duplicate_label_name') }}
      </div>
      <div
        v-if="emptyLabelName"
        class="fade-in error-message top-gap-sm"
      >
        {{ $t('dataPoints.empty_label') }}
      </div>
      <div class="d-flex justify-space-between align-center top-gap">
        <CustomSelect
          class="flex-grow-0"
          width="250px"
          :max-height="120"
          :items="availableLabels"
          :selected="selectedEntity"
          :disabled="availableLabels.length === 0"
          :placeholder="$t('models.add_label')"
          :server-filter="false"
          @selected-changed="addLabel"
          clickable
        />
        <v-btn
          color="primary"
          style="box-shadow: none"
          variant="outlined"
          :disabled="availableLabels.length === 0"
          @click="addAllLabels"
          rounded
        >
          <v-icon
            size="17"
            start
          >
            fas fa-plus
          </v-icon>
          {{ $t('dataPoints.add_all_labels') }}
        </v-btn>
      </div>
    </div>
    <GenerativeExtractorLabels
      v-if="extractionType === 'generative'"
      :labels="group.labels"
      @update-labels="(labels) => $emit('updateLabels', labels)"
    />
  </div>
</template>

<script>
import model_mixin from '@/mixins/model.js';
import feedback_mixin from '@/mixins/feedback.js';

import TextEditor from '@/components/common/elements/General/TextEditor';
import CustomSelect from '@/components/common/elements/Forms/CustomSelect.vue';
import GenerativeExtractorLabels from '@/components/extract/elements/LabelGroups/GenerativeExtractorLabels';
import GenerativeWarning from '@/components/extract/elements/Extractors/GenerativeWarning.vue';
import ItemName from '@/components/common/elements/General/ItemName.vue';

export default {
  name: 'ExtractionGroupConfig',

  mixins: [
    model_mixin,
    feedback_mixin,
  ],

  components: {
    TextEditor,
    GenerativeExtractorLabels,
    CustomSelect,
    GenerativeWarning,
    ItemName,
  },

  constants: {
    VALUE_TYPES_WITH_OPTIONS: ['regex', 'unspecified'],
    AGGREGATION_METHODS: ['line', 'divided_line', 'column', 'block', 'cluster', 'table_row', 'table_cell'],
  },

  data() {
    return ({
      selectedEntity: '',
      errorTimeOut: null,
      editName: '',
      newDisplayName: '',
      multi_page: false,
      keepBestOn: false,
      addingExcluded: false,
      newExcluded: '',
      selectedName: '',
      labels: [],
      modelVersions: [],
      duplicateName: false,
      emptyLabelName: false,
      switchOptions: [
        {
          info: this.$t('dataPoints.empty_primary_info'),
          label: this.$t('dataPoints.create_empty_primary_group'),
          onChange: this.handleShowPrimary,
          value: 'create_empty_primary_group',
        },
        {
          info: this.$t('dataPoints.full_primary_info'),
          label: this.$t('dataPoints.show_empty_primary'),
          onChange: this.handleFullPrimary,
          value: 'show_empty_primary',
        },
        {
          info: this.$t('dataPoints.single_item_group_info'),
          label: this.$t('dataPoints.single_item_group'),
          onChange: this.handleSingleItemGroup,
          value: 'single_item',
        }
      ],
      allPrimary: false,
      extractionType: '',
      newLabel: '',
      valueTypes: [
        {
          title: this.$t('datatable.value_type.unspecified'),
          value: 'unspecified',
        },
        {
          title: this.$t('datatable.value_type.date'),
          value: 'date',
        },
        {
          title: this.$t('datatable.value_type.integer'),
          value: 'integer',
        },
        {
          title: this.$t('datatable.value_type.float'),
          value: 'float',
        },
        {
          title: this.$t('datatable.value_type.regex'),
          value: 'regex',
        },
      ],
      generativeLabels: [],
      modelLabels: [],
    });
  },

  computed: {
    extractorDescription: {
      get() {
        return this.group.description || '';
      },
    },

    availableLabels() {
      return this.labels.filter(label => !this.group.labels.some(item => item.name === label.id));
    },

    localMethods() {
      return this.$options.constants.AGGREGATION_METHODS.map(method => ({ 
        title: this.$t(`dataPoints.${method}`), 
        value: method 
      }))
    },

    autoDetectClusters: {
      get() {
        return this.group.nb_clusters === 0;
      },
      set(value) {
        this.group.nb_clusters = value ? 0 : this.group.nb_clusters || 1;
        this.$emit('updated');
      },
    },

    hasForbiddenValues: {
      get() {
        return !!this.group.keep_best && this.group.forbidden_values && this.group.forbidden_values.length > 0;
      },
      set(value) {
        if (!value) {
          this.group.forbidden_values = [];
        } else {
          this.group.forbidden_values = [''];
          this.$nextTick(() => {
            this.$refs['forbiddenValue-0'][0].focus()
          });
        }
      },
    },
  },

  watch: {
    addingExcluded(newAddingExcluded) {if (newAddingExcluded) {
        setTimeout(() => this.$refs.NewExcludedInput.focus(), 10);
      }
    },

    keepBestOn(keepBestOn) {
      if (keepBestOn && !this.group.keep_best) {
        this.group.keep_best = 1;
      } else if (!keepBestOn) {
        this.group.keep_best = null;
      }
    },

    autoDetectClusters(auto) {
      const clusters = this.group.nb_clusters || 1;
      this.group.nb_clusters = auto ? 0 : clusters;
      this.$emit('updated');
    },

    extractionType(newType, oldType) {
      if (!oldType) {
        return;
      }
      switch (newType) {
        case 'model':
          this.generativeLabels = this.group.labels;
          this.group.labels = this.modelLabels;
          this.modelLabels = [];
          break;
        case 'generative':
          this.modelLabels = this.group.labels;
          this.group.labels = this.generativeLabels;
          this.generativeLabels = [];
          break;
      }
      this.$emit('updateExtractionType', newType);
      this.$emit('updateLabels', this.group.labels);
    },

    'group.labels': {
      handler() {      
        this.$emit('updated');
        this.allPrimary = this.group.labels.every(label => label.isPrimary);
      },
      deep: true,
    },
  },

  async mounted() {
    this.extractionType = this.group.is_generative ? 'generative' : 'model';
    if (this.extractionType === 'model') {
      this.handleMultiPage();
      this.keepBestOn = !!this.group.keep_best;
      const modelInfo = await this.getModelInfo(this.group.custom_model_id);
      if (modelInfo) {
        this.selectedName = modelInfo.name;
        const version = modelInfo.versions.find(v => v.version === this.group.custom_model_version);
        this.modelVersions = modelInfo.versions;
        this.labels = version.labels.map(entity => ({ id: entity, name: entity }));
      }
      this.allPrimary = this.group.labels.every(item => item.isPrimary);
    }
  },

  methods: {
    handleValueTypeChange(label) {
      if (label.valueType !== 'unspecified') {
        label.returnBlock = false;
      }
      this.$emit('updated');
    },

    togglePrimary() {
      this.group.labels.forEach((label) => {
        label.isPrimary = this.allPrimary;
      });
      this.$emit('updated');
    },

    saveDisplayName(name, index) {
      this.group.labels[index].displayName = name;
      this.editName = '';
      this.duplicateName = false;
      this.$emit('updated');
    },

    addLabel(name) {
      if (!name) {
        return;
      }
      this.$emit('updateLabels', [
        ...this.group.labels,
        {
          name,
          isPrimary: this.group.labels.length < 1,
          valueType: 'unspecified',
          displayName: '',
          returnBlock: false,
          regex: { pattern: '', substitute: '' },
          precision: null,
        },
      ])
      this.$nextTick(() => {
        this.selectedEntity = '';
      });
    },

    addAllLabels() {
      const remainingLabels = this.availableLabels.map((item, index) => ({
        name: item.id,
        isPrimary: this.group.labels.length === 0 && index === 0,
        valueType: 'unspecified',
        displayName: '',
        returnBlock: false,
        regex: { pattern: '', substitute: '' },
        precision: null,
      }));
      
      this.$emit('updateLabels', [
        ...this.group.labels,
        ...remainingLabels,
      ]);
    },

    handleVersionChange(newVersion) {
      const selectedVersion = this.modelVersions.find(v => v.version === newVersion);
      this.labels = selectedVersion.labels.map(entity => ({ id: entity, name: entity }));
      const newLabels = [];
      this.group.labels.forEach((label) => {
        if (selectedVersion.labels.includes(label.name)) {
          newLabels.push(label);
        }
      });
      this.$emits('updateLabels', newLabels);
    },

    resetInput() {
      this.duplicateName = false;
      this.emptyLabelName = false;
      this.editName = '';
      this.newDisplayName = ''; 
    },

    blurRegexField() {
      this.$emit('updated');
      this.$nextTick(() => {
        this.$refs.regexField.blur();
      });
    },

    blurSubstituteField() {
      this.$emit('updated');
      this.$nextTick(() => {
        this.$refs.substituteField.blur();
      });
    },

    checkPrimary(index) {    
      if (this.errorTimeOut) {
        clearTimeout(this.errorTimeOut);
      }
      if (this.group.labels.every(label => !label.isPrimary)) {
        this.primaryLabelError();
        this.$nextTick(() => this.group.labels[index].isPrimary = true);
      } else if (
        this.group.show_empty_primary && !this.group.labels[index].isPrimary
      ) {
        this.$store.commit('setErrorMessage', this.$t('dataPoints.need_all_primary'));
        this.$store.commit('setSnackbar', true);
        this.errorTimeOut = setTimeout(
          () => this.$store.commit('setErrorMessage', this.$t('default_error')),
          5000
        );
        this.$nextTick(() => this.group.labels[index].isPrimary = true);
      } else {
        this.$emit('updateLabels', this.group.labels);
      }
    },

    deleteLabel(deleted) {
      this.$emit('updateLabels', this.group.labels.filter((label) => label.name !== deleted));
    },

    editDisplayName(labelName) {
      this.editName = labelName;
      this.$nextTick(() => {
        this.$refs[`displayName-${labelName}`][0].focus();
      });
    },

    noDuplicates(name, index) {
      const res = this.group.labels.reduce(
        (res, item, i) => res && (index === i || !name || (item.name !== name && item.displayName !== name)),
        true
      );      
      this.duplicateName = !res;
      return res;
    },

    noEmptyLabel(name) {
      this.emptyLabelName = !name;
      return !!name;
    },

    handleFullPrimary(fullPrimary) {
      if (fullPrimary) {
        this.group.create_empty_primary_group = false;
        this.group.labels.forEach((label) => label.isPrimary = true);
      }
      this.$emit('updated');
    },

    handleMultiPage(multiPage) {
      if (multiPage) {
        this.group.multi_page = true;
        this.$emit('updated');
      }
    },

    handleShowPrimary(showPrimary) {
      if (showPrimary) {
        this.group.show_empty_primary = false;
      }
      this.$emit('updated');
    },

    handleSingleItemGroup(singleItem) {
      this.group.single_item = singleItem;
      this.$emit('updated');
    },

    removeExcluded(value) {
      this.group.forbidden_values = this.group.forbidden_values.filter(v => v !== value);
      this.$emit('updated');
    },

    handleNewExcluded() {
      this.group.forbidden_values.push(this.newExcluded.trim());
      this.newExcluded = '';
      this.$emit('updated');
    },

    handleKeepBestInput() {
      this.keepBestOn = !!this.group.keep_best;
      this.$emit('updated');
    },

    handleMethodChange(method) {
      if (method === 'cluster' && this.type === 'data_point') {
        this.group.nb_clusters = 0;
        this.group.table_display = false;
      }
      if (method !== 'blocks') {
        this.group.multi_page = false;
      }
      this.$emit('updated');
    },
  },

  props: {
    type: {
      type: String,
      required: true,
    },

    group: {
      type: Object,
      required: true,
    },
  },

  emits: ['updated', 'updateLabels', 'updateExtractionType', 'updateDescription'],
}
</script>

<style lang="scss" scoped>
.extraction-group-config {
  .config-input-field {
    display: inline-block;
    background-color: #ffffff00;
    padding: 0 !important;
    border: none;
    border-bottom: 1px solid rgb(var(--v-theme-primary));
    width: 50px;

    &:focus {
      outline: none;
      border-bottom: 1px solid rgb(var(--v-theme-primary));
    }

    &:focus-visible {
      outline: none;
      border-bottom: 1px solid rgb(var(--v-theme-primary));
    }
  }

  .disabled-text {
    opacity: 0.5;
  }

  .label-card {
    padding: 0;
  }

  .label-row {
    margin: 0 !important;
    position: relative;
    height: 55px;
  }

  .label-bottom {
    border-bottom: 1px solid #eee;
  }

  .v-col {
    height: 100%;
    padding: 0 12px;
    display: flex;
    align-items: center;
  }

  .regex-config {
    // background-color: rgb(var(--v-theme-grey-lighten2));
    // background-color: #f4f5f9;
    font-size: 0.8rem;
  }

  .rounded-card {
    border-radius: 0.3125rem;
  }

  .regex-field {
    display: inline-block;
    background-color: #ffffff00;
    padding: 0 !important;
    border: none;
    border-bottom: 1px solid rgb(var(--v-theme-primary));
    width: 200px;

    &:focus {
      outline: none;
      border-bottom: 1px solid rgb(var(--v-theme-primary));
    }

    &:focus-visible {
      outline: none;
      border-bottom: 1px solid rgb(var(--v-theme-primary));
    }
  }
  .no-label-sign {
    font-style: italic;
    text-align: center;
  }

  .model-name {
    width: fit-content;
    max-width: calc(100% - 120px);
  }

  .version-select {
    margin-top: -7px !important;
    width: 120px;
  }

  .add-all-labels {
    font-size: 12px;

    &__enabled {
      cursor: pointer;
      color: rgb(var(--v-theme-primary));
      text-decoration: underline;
      font-style: normal;
    }

    &__disabled {
      cursor: default;
      color: #999;
      text-decoration: none;
      font-style: italic;
    }
  }

  .word-input {
    width: 200px;
    position: relative;
    top: -4px;
    left: -10px;
    margin-top: -11px !important;
  }

  .info-box {
    background-color: rgb(var(--v-theme-primary-lighten2));
    border-radius: 6px;
    padding: 6px 17px;
    padding-bottom: 10px;
    width: fit-content;

    .info-icon {
      margin-right: 2px;
      top: -1px;
    }
  }

  .close-button {
    position: absolute;
    right: 7px;
    top: 7px;
    z-index: 3;
  }

  .error-message {
    color: rgba(var(--v-theme-error));
    font-size: 0.80rem;
    font-style: italic;
  }
}
</style>
