<template>
  <div class="models-table top-gap-lg">
    <TableActions
      type="models"
      :number-of-selected="selected.length"
      @edit-click="handleEdit"
      @delete-click="handleDeleteButton"
      @create-click="$emit('createClick')"
      @filter-change="(filter) => modelFilter = filter"
    />
    <TableWithFooter
      type="models"
      :loading="loading"
      :paginated-items-length="paginatedModels.length"
      :total="totalActiveModels"
      :current-page="currentPage"
      :items-per-page="itemsPerPage"
      @change-items-per-page="(_itemsPerPage) => itemsPerPage = _itemsPerPage"
      @change-page="(page) => currentPage = page"
      include-footer-in-table-card
    >
      <template #header>
        <v-col cols="auto">
          <SortButton v-model="sortDesc" />
          <v-checkbox
            v-model="allSelected"
            class="mt-0"
            @change="toggleSelectAll"
            hide-details
          />
        </v-col>
        <v-col cols="2">
          {{ $t('dataPoints.model') }}
        </v-col>
        <v-col
          cols="1"
          class="justify-space-between"
        >
          {{ $t('models.version') }}
          <v-tooltip top>
            <template #activator="{ props }">
              <v-icon
                class="info-icon pointer"
                color="primary"
                size="16"
                v-bind="props"
              >
                fas fa-info-circle
              </v-icon>
            </template>
            {{ $t('models.visualize_version') }}
          </v-tooltip>
        </v-col>
        <v-col cols="1">
          {{ $t('models.training_date') }}
        </v-col>
        <v-col cols="3">
          {{ $t('models.labels') }}
        </v-col>
        <v-col>
          {{ $t('models.recall') }}
        </v-col>
        <v-col>
          {{ $t('models.precision') }}
        </v-col>
        <v-col>
          {{ $t('models.f_1') }}
        </v-col>
        <v-col>
          {{ $t('models.detailed_score') }}
        </v-col>
      </template>
      <template #body>
        <v-container
          class="pa-0"
          fluid
        >
          <div
            v-for="item in paginatedModels"
            :key="item.id"
          >
            <v-row
              class="table-row fade-in table-row-height"
              style="border-bottom: 1px solid #eee;"
            >
              <v-col cols="auto">
                <v-checkbox
                  v-model="item.selected"
                  class="left-gap mt-0"
                  @change="handleSelect"
                  hide-details
                />
              </v-col>
              <v-col cols="2">
                <div v-if="item.selectedVersion.status === 'error'">
                  <v-tooltip
                    :key="renderKey"
                    bottom
                  >
                    <template #activator="{ props }">
                      <v-icon
                        class="right-gap-sm"
                        style="top: -3px"
                        color="primary"
                        size="16"
                        v-bind="props"
                      >
                        fas fa-exclamation-circle
                      </v-icon>
                    </template>
                    {{ $t('models.training_failed') }}
                  </v-tooltip>
                </div>
                <ItemName
                  :key="item.id"
                  :style="{ width: item.selectedVersion.status === 'error' ? '85%' : '100%' }"
                  :item="item"
                  :editing="editingModel === item.id"
                  :clickable="false"
                  :validator="validateName"
                  :hint="`${$t('forms.name_hint')} ${$t('forms.unique_hint')}`"
                  @save-file-name="(id, newName) => saveModelName(id, newName)"
                  editing-allowed
                />
              </v-col>
              <v-col cols="1">
                <CustomSelect
                  name-field="version"
                  value-field="version"
                  width="65px"
                  :max-width="65"
                  :items="item.filteredVersions"
                  :selected="item.selectedVersion.version"
                  :show-placeholder="false"
                  @get-more="(version) => handleVersionFilter(item, version)"
                  @selected-changed="(version) => handleVersionChange(item, version)"
                  clickable
                />
                <v-tooltip top>
                  <template #activator="{ props }">
                    <v-icon
                      class="clickable ml-1"
                      color="primary"
                      size="14"
                      v-bind="props"
                      @click="handleDeleteVersion(item)"
                    >
                      fas fa-trash
                    </v-icon>
                  </template>
                  {{ $t('models.delete_version') }}
                </v-tooltip>
              </v-col>
              <v-col cols="1">
                <small class="gray--text">
                  {{ formatDate(item.selectedVersion.training_date) || '—' }}
                </small>
              </v-col>
              <v-col cols="3">
                <div
                  v-if="item.selectedVersion.labels.length > 0"
                  style="overflow: hidden; white-space: nowrap;"
                >
                  <MaxWidthChip
                    class="mr-1 mb-0"
                    :max-width="175"
                    :text-array="[item.selectedVersion.labels[0]]"
                    small
                  />
                  <div
                    v-if="item.selectedVersion.labels.length > 1"
                    class="inline-middle"
                  >
                    <MaxWidthChip
                      class="mr-1 mb-0"
                      :text-array="[`+${item.selectedVersion.labels.length - 1}`]"
                      small
                    />
                    <v-tooltip
                      v-if="expanded.length === 0 || expanded[0].id !== item.id"
                      color="#423F4F"
                      right
                    >
                      <template #activator="{ props }">
                        <v-icon
                          class="clickable"
                          size="16"
                          v-bind="props"
                          @click="expanded = [item]"
                        >
                          fas fa-chevron-right
                        </v-icon>
                      </template>
                      <span style="color: white">
                        {{ `${$t('show_all')} +${item.selectedVersion.labels.length - 1}` }}
                      </span>
                    </v-tooltip>
                    <v-icon
                      v-else
                      class="clickable"
                      size="16"
                      @click="expanded = []"
                    >
                      fas fa-chevron-down
                    </v-icon>
                  </div>
                </div>
                <div v-else>
                  <MaxWidthChip
                    color="#999999"
                    :text-array="[$tc('datatable.header.none', 2)]"
                    small
                  />
                </div>
              </v-col>
              <v-col>
                <div
                  v-if="
                    item.selectedVersion &&
                      item.selectedVersion.training_stats &&
                      item.selectedVersion.training_stats.overall_stats &&
                      item.selectedVersion.training_stats.overall_stats.recall
                  "
                >
                  {{ Math.round(item.selectedVersion.training_stats.overall_stats.recall.last.toFixed(2) * 100) }}%
                </div>
                <div v-else>
                  N/A
                </div>
              </v-col>
              <v-col>
                <div
                  v-if="
                    item.selectedVersion &&
                      item.selectedVersion.training_stats &&
                      item.selectedVersion.training_stats.overall_stats &&
                      item.selectedVersion.training_stats.overall_stats.precision
                  "
                >
                  {{ Math.round(item.selectedVersion.training_stats.overall_stats.precision.last.toFixed(2) * 100) }}%
                </div>
                <div v-else>
                  N/A
                </div>
              </v-col>
              <v-col>
                <div
                  v-if="
                    item.selectedVersion &&
                      item.selectedVersion.training_stats &&
                      item.selectedVersion.training_stats.overall_stats &&
                      item.selectedVersion.training_stats.overall_stats.f1
                  "
                >
                  {{ Math.round(item.selectedVersion.training_stats.overall_stats.f1.last.toFixed(2) * 100) }}%
                </div>
                <div v-else>
                  N/A
                </div>
              </v-col>
              <v-col>
                <v-tooltip
                  color="#423F4F"
                  right
                >
                  <template #activator="{ props }">
                    <v-btn
                      color="primary"
                      style="height: 30px; margin-top: -4px"
                      :disabled="!(
                        item.selectedVersion.training_stats &&
                        item.selectedVersion.training_stats.overall_stats &&
                        item.selectedVersion.training_stats.overall_stats.f1 &&
                        item.selectedVersion.training_stats.label_stats)
                      "
                      v-bind="props"
                      @click="handleScores(item)"
                      rounded
                    >
                      <v-icon size="16">
                        fas fa-eye
                      </v-icon>
                    </v-btn>
                  </template>
                  <span style="color: white">
                    {{ $t('show') }}
                  </span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row
              v-if="expanded.length > 0 && expanded[0].id === item.id"
              class="table-row"
              style="background-color: rgb(var(--v-theme-grey-darken1))"
            >
              <v-col cols="auto" />
              <v-col cols="4" />
              <v-col
                cols="6"
                class="flex-wrap pt-3"
                style="margin-left: 60px"
              >
                <MaxWidthChip
                  v-for="(label, i) in item.selectedVersion.labels"
                  :key="i"
                  class="flex-shrink-0 mr-2 mb-3"
                  :text-array="[label]"
                  show-full
                  small
                />
              </v-col>
            </v-row>
          </div>
        </v-container>
      </template>
    </TableWithFooter>
    <DeleteDialog
      v-model="deleteDialog"
      :title="$t('models.delete_title')"
      :message="$t('models.confirm_delete')"
      @confirm="deleteModel"
      @close="deleteDialog = false"
    />
    <DeleteDialog
      v-model="deleteVersionDialog"
      :title="$t('models.delete_version')"
      :message="versionDeleteMessage"
      @confirm="deleteModelVersion"
      @close="cancelDeleteVersion"
    />
    <v-dialog
      v-model="showScores"
      max-width="600"
    >
      <v-card
        v-if="labelScores.length > 0"
        class="dialog-card"
      >
        <v-container
          class="pa-0 table-row-height"
          fluid
        >
          <v-row class="table-row table-row__header table-row-height">
            <v-col cols="4">
              {{ $t('models.labels') }}
            </v-col>
            <v-col class="text-center">
              {{ $t('models.recall') }}
            </v-col>
            <v-col class="text-center">
              {{ $t('models.precision') }}
            </v-col>
            <v-col class="text-center">
              {{ $t('models.f_1') }}
            </v-col>
          </v-row>
        </v-container>
        <div
          v-if="labelScores.length === 0"
          class="table-row fade-in table-row-height"
          style="text-align: center; padding-top: 15px; opacity: 0.5"
        >
          <i>{{ $t('docTypes.no_results') }}</i>
        </div>
        <v-container
          v-else
          class="pa-0"
          fluid
        >
          <v-row
            v-for="item in labelScores"
            :key="item.id"
            class="table-row fade-in table-row-height"
          >
            <v-col
              cols="4"
            >
              <v-tooltip right>
                <template #activator="{ props }">
                  <div
                    v-bind="props"
                    class="ellipsis"
                  >
                    {{ item.name }}
                  </div>
                </template>
                {{ item.name }}
              </v-tooltip>
            </v-col>
            <v-col class="text-center">
              <span v-if="item.recall && item.recall.last >= 0">
                {{ Math.round(item.recall.best.toFixed(2) * 100) }}%
              </span>
              <span v-else>
                N/A
              </span>
            </v-col>
            <v-col class="text-center">
              <span v-if="item.precision && item.precision.last >= 0">
                {{ Math.round(item.precision.last.toFixed(2) * 100) }}%
              </span>
              <span v-else>
                N/A
              </span>
            </v-col>
            <v-col class="text-center">
              <span v-if="item.f1 && item.f1.last >= 0">
                {{ Math.round(item.f1.last.toFixed(2) * 100) }}%
              </span>
              <span v-else>
                N/A
              </span>
            </v-col>
          </v-row>
        </v-container>
        <div class="d-flex justify-end mt-5">
          <v-btn
            color="primary"
            @click="handleClose"
            rounded
          >
            {{ $t('close') }}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from 'lodash';
import { http } from '@/plugins/axios';
import { ModelAPI } from '@/API/extract/ModelAPI';
import { formatDate } from '@/utils/classes/Formatting';
import { isNameValid } from '@/utils/FormValidation';
import model_mixin from '@/mixins/model.js';
import SortButton from '@/components/common/elements/Tables/SortButton';
import TableWithFooter from '@/components/common/elements/Tables/TableWithFooter';
import { useTableWithFooter } from '@/composables/useTableWithFooter.js';
import DeleteDialog from "@/components/common/elements/Tables/DeleteDialog";
import ItemName from '@/components/common/elements/General/ItemName';
import MaxWidthChip from "@/components/common/elements/General/MaxWidthChip";
import TableActions from '@/components/common/elements/Tables/TableActions';
import CustomSelect from '@/components/common/elements/Forms/CustomSelect';


export default {
  name: 'ModelsTable',

  mixins: [
    model_mixin,
  ],

  components: {
    TableActions,
    ItemName,
    DeleteDialog,
    TableWithFooter,
    MaxWidthChip,
    SortButton,
    CustomSelect,
  },

  data() {
    const { itemsPerPage, currentPage } = useTableWithFooter(
      `${this.$route.path}_${this.$options.name}`);

    return {
      sortDesc: true,
      modelFilter: '',
      deleteDialog: false,
      deleteVersionDialog: false,
      modelToDelete: -1,
      versionToDelete: -1,
      versionDeleteMessage: '',
      modelScores: null,
      showScores: false,
      allSelected: false,
      statusCheck: null,
      loading: false,
      paginatedModels: [],
      expanded: [],
      renderKey: 10,
      editingModel: -1,
      itemsPerPage,
      currentPage,
    };
  },

  computed: {
    totalPages() {
      return Math.ceil(this.totalActiveModels / this.itemsPerPage);
    },

    trimmedFilter() {
      return this.modelFilter.trim().toLowerCase();
    },

    selected: {
      get() {
        if (this.paginatedModels.length > 0) {
          return this.paginatedModels.filter(item => item.selected);
        }
        return [];
      },
      set() {
        //pass
      }
    },

    labelScores() {
      if (this.showScores) {
        const labels = Object.keys(this.modelScores.selectedVersion.training_stats.label_stats);
        return labels.reduce((res, label) =>
          [...res, { name: label, ...this.modelScores.selectedVersion.training_stats.label_stats[label] }],
        []);
      }
      return [];
    },
  },

  watch: {
    sortDesc() {
      this.getActiveModels();
    },

    totalActiveModels(total) {
      if (this.trimmedFilter === '') {
        this.totalActiveModelsDisplay = total;
      }
    },

    deleteDialog(on) {
      if (on) {
        clearInterval(this.statusCheck);
      } else {
        this.modelsCountCheck();
      }
    },

    itemsPerPage() {
      this.resetCurrentPage();
      this.modelsCountCheck();
    },

    currentPage() {
      this.allSelected = false;
      this.customModels.forEach(item => {
        item.selected = false;
      });
      this.modelsCountCheck();
    },

    trimmedFilter: _.debounce(
      function() {
        this.resetCurrentPage();
        this.modelsCountCheck();
      }, 300
    ),
  },

  mounted() {
    this.modelsCountCheck();
  },

  unmounted() {
    clearInterval(this.statusCheck);
  },

  methods: {
    formatDate,

    validateName(name) {
      return isNameValid(name);
    },

    resetCurrentPage() {
      this.currentPage = 1;
      this.allSelected = false;
      this.customModels.forEach(m => {
        m.selected = false;
      });
    },

    async getActiveModels(
      offset = (this.currentPage - 1) * this.itemsPerPage,
      limit = this.itemsPerPage,
      loading = true)
    {
      if (loading) {
        this.loading = true;
      }
      try {
        const response = await ModelAPI.get(
          offset, limit, this.trimmedFilter || '', 'active', this.sortDesc,
        );
        this.paginatedModels = response.data
          .map(item => {
            const latest = Math.max(...item.versions.map(v => v.version));
            return {
              ...item,
              versions: item.versions.sort((a, b) => b.version - a.version),
              selectedVersion: item.versions.find((v) => v.version === latest),
              filteredVersions: item.versions,
            };
          });
        this.totalActiveModels = parseInt(response.headers['x-total-count'], 10);
      } catch (error) {
        clearInterval(this.statusCheck);
      } finally {
        this.loading = false;
      }
    },

    async getModelsCount(status) {
      try {
        if (status === 'active') {
          this.loading = true;
        }
        let includeError = true;
        if (status === 'pending') {
          includeError = false;
        }
        const response = await ModelAPI.get(
          0, 1, null, status, null, includeError,
        );
        this[`total${this.statusMapping[status]}ModelsDisplay`] = parseInt(
          response.headers['x-total-count'], 10
        );
      } catch (error) {
        clearInterval(this.statusCheck);
      } finally {
        this.loading = false;
      }
    },

    modelsCountCheck() {
      this.getModelsCount('pending');
      this.getActiveModels();
      clearInterval(this.statusCheck);
      this.statusCheck = setInterval(() => {
        if (this.totalTrainingModelsDisplay === 0) {
          clearInterval(this.statusCheck);
        } else {
          this.getModelsCount('pending');
          this.getActiveModels(
            this.itemsPerPage * (this.currentPage - 1),
            this.itemsPerPage,
            false
          );
        }
      }, 3000);
    },

    handleSelect() {
      this.allSelected = this.paginatedModels.every(f => f.selected);
      this.renderKey++;
    },

    handleVersionChange(model, newVersion) {
      model.selectedVersion = model.versions.find(v => v.version === newVersion);
    },

    getFilteredVersions(model, filter) {
      const res = model.versions.filter(v => v.version.toString().includes(filter));
      return res; 
    },

    handleVersionFilter(model, filter) {
      model = {
        ...model,
        filteredVersions: this.getFilteredVersions(model, filter),
      };
    },

    handleDeleteVersion(model) {
      this.modelToDelete = model.id;
      this.versionToDelete = model.selectedVersion.version;
      this.versionDeleteMessage = this.$tc('models.confirm_delete_version', model.versions.length === 1 ? 1 : 2);
      this.deleteVersionDialog = true;
    },

    cancelDeleteVersion() {
      this.modelToDelete = -1;
      this.versionToDelete = -1;
      this.versionDeleteMessage = '';
      this.deleteVersionDialog = false;
    },

    toggleSelectAll() {
      this.paginatedModels.forEach(item => {
        item.selected = this.allSelected;
      });
      this.renderKey++;
    },

    async deleteModel() {
      await Promise.all(this.selected.map(async model => {
        try {
          return await http.delete(`model/${model.id}`);
        } catch (error) {
          return
        }
      }));
      this.finishDeletion();
    },

    async deleteModelVersion() {
      if (this.modelToDelete > 0 && this.versionToDelete > 0) {
        await http.delete(`model/${this.modelToDelete}/version/${this.versionToDelete}`);
      }
      this.finishVersionDeletion();
    },

    async finishDeletion() {
      this.modelFilter = '';
      await this.getActiveModels();
      this.currentPage = 1;
      this.selected.forEach(model => {
        model.selected = false;
        this.customModels = [...this.customModels.filter(item => item.id !== model.id)];
      });
      this.deleteDialog = false;
      await this.$store.commit(
        'setSuccessMessage', this.$t('models.deleted_message')
      );
      this.$store.commit('setSuccessSnackbar', true);
    },

    async finishVersionDeletion() {
      const model = this.paginatedModels.find(item => item.id === this.modelToDelete);
      model.versions = [...model.versions.filter(v => v.version !== this.versionToDelete)];
      if (model.versions.length > 0) {
        model.selectedVersion = model.versions[0];
      }
      await this.getActiveModels();
      this.deleteVersionDialog = false;
      this.versionToDelete = -1;
      this.modelToDelete = -1;
      const success_message = model.versions.length > 0 ?
        this.$t('models.version_deleted_message') :
        this.$t('models.deleted_message');
      await this.$store.commit(
        'setSuccessMessage', success_message
      );
      this.$store.commit('setSuccessSnackbar', true);
    },

    handleDeleteButton() {
      this.deleteDialog = true;
    },

    handleScores(model) {
      this.modelScores = model;
      this.showScores = true;
    },

    handleClose() {
      this.showScores = false;
      this.modelScores = null;
    },

    handleEdit(){
      this.editingModel = this.selected[0].id;
    },

    async saveModelName(id, newName){
      if (newName !== ''){
        this.$store.commit('setLoadingScreen', true);
        try {
          await http.put(`/model/${id}/`,{ name: newName});
          await this.getActiveModels();
          this.$store.commit(
            'setSuccessMessage', this.$t('models.updated_message')
          );
          this.$store.commit('setSuccessSnackbar', true);
        } catch (error) {
          error.handleGlobally && error.handleGlobally();
        }
        finally {
          this.$store.commit('setLoadingScreen', false);
        }
      }
    }
  },

  emits: ['createClick'],
}
</script>
