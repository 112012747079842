import { createApp } from 'vue';
import App from './App.vue';
import { vuetify } from './plugins/vuetify';
import router from './plugins/router';
import { store } from './plugins/store';
import { i18n } from './plugins/i18n/i18n';
import loadConfig from './plugins/config/loadConfig';

const app = createApp(App);

app.config.productionTip = false;

loadConfig().then(config => {
  if (config) {
    app.use(i18n);
    app.use(router);
    app.use(store);
    app.use(vuetify);

    app.component('App', App);

    const instance = app.mount('#app');

    store.commit('setConfig', config);
    store.commit('setVersion', '24.12.1-rc10');

    window.app = instance;
  }
});
