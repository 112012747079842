<template>
  <v-card
    elevation="0"
    class="category-container mb-7 pa-5"
    :style="{
      'background-color':
        categoryMap[classifiedPages.category_id] === 'Unknown' ?
          'rgb(224, 204, 184)	 !important' : 'rgb(221, 219, 219) !important'
    }"
    :class="{
      'mr-0': last,
      'mr-7': !last,
    }"
  >
    <ItemName
      v-if="classifiedPages && classifiedPages.pages"
      :key="classifiedPages.id"
      class="text-overline primary--text"
      style="z-index: 202; line-height: 1rem;"
      font-size="11px"
      :style="{maxWidth: `${classifiedPages.pages.length * 110}px`}"
      :item="{
        id: classifiedPages.id,
        name: categoryMap[classifiedPages.category_id],
      }"
      :clickable="false"
      :show-id="false"
    />
    <v-card-text class="d-flex flex-wrap justify-center align-center pa-0">
      <FilePage
        v-for="page, i in classifiedPages.pages"
        :key="page.id"
        :page="page"
        :class="{
          'right-gap': i !== classifiedPages.pages.length - 1,
        }"
        :categories="categories"
        :category-map="categoryMap"
        :display-categories="displayCategories"
        :selected="selectedPage && selectedPage.id === page.id"
        @select="id => $emit('selectPage', id)"
      />
    </v-card-text>
  </v-card>
</template>
<script>
import FilePage from '@/components/extract/elements/Workflows/PageCorrection/FilePage';
import ItemName from '@/components/common/elements/General/ItemName';

export default {
  name: 'FileContainer',

  components: {
    FilePage,
    ItemName,
  },

  props: {
    classifiedPages: {
      type: Object,
      required: true,
    },
    last: {
      type: Boolean,
      default: false,
    },
    displayCategories: {
      type: Boolean,
      default: false,
    },
    categories: {
      type: Array,
      default: () => [],
    },
    categoryMap: {
      type: Object,
      default: () => {},
    },
    selectedPage: {
      type: Object,
      default: () => {},
    },
  },

  emits: ['selectPage'],
}
</script>
<style lang="scss" scoped>
  .category-container {
    padding-bottom: 10px !important;
  }

  .file-container__header {
    max-width: 150px;
    max-height: 20px;
  }

  .item-name-style {
    z-index: 202;
    line-height: 1rem;
    max-width: 110px;
    font-size: 11px;
  }
</style>