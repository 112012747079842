import { http } from '@/plugins/axios';

const BASE_PATH = 'search/api/v1/';

export class SearchFileAPI {
  static getFiles(
    folder_id,
    offset = 0,
    limit = 20,
    name_filter = '',
    include_subfolders = false,
    sort_desc = true,
  ) {
    return http
      .get(
        `${BASE_PATH}file/`,
        {
          params: {
            folder_id,
            offset,
            limit,
            name_filter,
            include_subfolders,
            sort_desc,
          },
        })
      .then(response => {
        const totalFiles = parseInt(response.headers['x-total-count'], 10);
        return Promise.resolve([response.data, totalFiles])
      })
      .catch(error => Promise.reject(error));
  }

  static post(file, folderId) {
    const formData = new FormData();
    formData.append('file_in', file);
    formData.append('folder_id', folderId);
    const headerConfig = { headers: { 'Content-Type': 'multipart/form-data' } }
    return http
      .post(`${BASE_PATH}file/`, formData, headerConfig)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static put(fileId, data) {
    return http
      .put(`${BASE_PATH}file/${fileId}/`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  static delete(fileId) {
    return http
      .delete(`${BASE_PATH}file/${fileId}/`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  static getFileMetadata(fileName) {
    return http
      .get(`${BASE_PATH}metadata/file/${fileName}`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static updateFileMetadata(metadata) {
    return http
      .post(`${BASE_PATH}metadata/file`, metadata)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }
}
