<template>
  <div
    class="classification-validation d-flex"
    :class="{'shifted': sidebarWidth > 0}"
  >
    <CustomSelect
      v-if="showCategories"
      class="fade-in mr-3"
      placeholder="select"
      width="250px"
      :selected="internalValue"
      :items="categories"
      @selected-changed="(newValue) => internalValue = newValue"
      top-panel
    />
    <v-btn
      v-if="showSubmitButton"
      class="clickable mr-3"
      color="primary"
      style="box-shadow: none"
      @click="$emit('submit-review')"
      rounded
    >
      <v-icon
        v-if="submittingReview"
        left
      >
        fas fa-spinner fa-pulse
      </v-icon>
      {{ $t('workflows.documents.submit_review') }}
    </v-btn>
    <v-btn
      class="clickable mr-3"
      color="primary"
      style="box-shadow: none"
      @click="showDiscardDialog = true"
      rounded
    >
      {{ nbSelectedPages > 0 && $t('workflows.documents.delete_pages') || $t('workflows.documents.discard') }}
    </v-btn>
    <v-btn
      v-if="showClearSelectionButton"
      class="clickable mr-3"
      color="primary"
      style="box-shadow: none;"
      @click="$emit('cancel-select-pages')"
      rounded
    >
      {{ $t('forms.clear_selection') }}
    </v-btn>
    <v-btn
      v-if="isExtractUp"
      class="clickable mr-3"
      color="primary"
      style="box-shadow: none"
      @click="showCopyToDatasetDialog = !showCopyToDatasetDialog"
      rounded
    >
      {{ $t('workflows.documents.copy_to_dataset') }}
    </v-btn>
  </div>
  <DiscardDialog
    v-model="showDiscardDialog"
    :title="nbSelectedPages > 0 && $t('workflows.documents.discard_pages_title') || $t('workflows.documents.discard_document_title')"
    :show-dialog="showDiscardDialog"
    :discard-reasons="discardReasons"
    :selected-reason="selectedReason || defaultDiscardReason"
    :custom-reason="customReason"
    @update:show-dialog="(value) => showDiscardDialog = value"
    @update:selected-reason="(value) => selectedReason = value"
    @update:custom-reason="(value) => customReason = value"
    @submit-discard-review="handleDiscard"
  />
  <CopyToDatasetDialog
    v-model="showCopyToDatasetDialog"
    :show-dialog="showCopyToDatasetDialog"
    @update:show-dialog="(value) => showCopyToDatasetDialog = value"
    @copy-to-dataset="(value) => $emit('copy-to-dataset', value)"
  />
</template>
<script>

import DiscardDialog from '@/components/extract/elements/Documents/DiscardDialog';
import CopyToDatasetDialog from '@/components/extract/elements/Documents/CopyToDatasetDialog';
import CustomSelect from '@/components/common/elements/Forms/CustomSelect';
import { ProductHealthCheck } from '@/utils/ProductHealthCheck';

export default {
  name: 'ClassificationValidation',

  components: {
    CustomSelect,
    DiscardDialog,
    CopyToDatasetDialog,
  },

  data() {
    return {
      openMenu: false,
      showDiscardDialog: false,
      selectedReason: '',
      showCopyToDatasetDialog: false,
      customReason: '',
      internalValue: this.modelValue,
      isExtractUp: false,
    }
  },

  computed: {
    showCategories() {
      return this.isBundle && this.nbSelectedPages > 0 || !this.isBundle && this.nbSelectedPages === 0;
    },

    showClearSelectionButton() {
      return this.nbSelectedPages > 0;
    },

    showSubmitButton() {
      return this.nbSelectedPages === 0;
    },

    defaultDiscardReason() {
      return this.nbSelectedPages === 0 && this.$t('workflows.documents.file_corrupted') || this.$t('workflows.documents.blank_page')
    },
  },

  watch: {
    modelValue: {
      immediate: true,
      handler(value) {
        this.internalValue = value;
      }
    },

    internalValue(value) {
      this.$emit('update:modelValue', value);
      if (this.isBundle) {
        this.$emit('save-categories');
      }
      this.openMenu = false;
    },
  },

  mounted() {
    this.checkExtractStatus();
  },

  methods: {
    handleDiscard() {
      this.$emit('submit-discard-reason', this.customReason || this.selectedReason || null);
      this.showDiscardDialog = false;
    },

    async checkExtractStatus() {
      try {
        const res = await ProductHealthCheck.serviceHealthAPI.extract.get();
        this.isExtractUp = res.status === 'running';
      } catch {
        this.isExtractUp = false;
      }
    },
  },

  props: {
    modelValue: {
      type: Number,
      required: true
    },

    submittingReview: {
      type: Boolean,
      default: false
    },

    isBundle: {
      type: Boolean,
      default: true
    },
    
    nbSelectedPages: {
      type: Number,
      default: 0,
    },

    discardReasons: {
      type: Array,
      default: () => [],
    },

    categories: {
      type: Array,
      default: () => [],
    },

    sidebarWidth: {
      type: Number,
      default: 0,
    },
  },

  emits: ['update:modelValue','cancel-select-pages', 'save-categories', 'submit-review', 'submit-discard-reason', 'copy-to-dataset'],
}
</script>
<style lang="scss" scoped>
.classification-validation {
  position: fixed;
  bottom: 5%;
  left: 50%;
  transform: translate(-25%);
  z-index: 900;

  &.shifted {
    left: calc(50% - 600px / 4);
  }
}
</style>
