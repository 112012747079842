<template>
  <div class="extraction-view page-padding py-7">
    <PageTabs
      v-model="activeTab"
      :tabs="tabItems"
      wide
    />
    <ModelsTable
      v-if="activeTab === 'models'"
      @create-click="activeTab = 'training'"
    />
    <ExtractionModelTraining
      v-if="activeTab === 'training'"
      :dataset-id="datasetId"
      @change-tab="tab => activeTab = tab"
    />
  </div>
</template>

<script>
import model_mixin from '@/mixins/model.js';
import ModelsTable from '@/components/extract/elements/Models/ModelsTable';
import ExtractionModelTraining from '@/components/extract/views/Studio/ExtractionModelTraining';
import PageTabs from '@/components/common/elements/General/PageTabs';

export default {
  name: 'ExtractionView',

  mixins: [
    model_mixin,
  ],

  components: {
    ModelsTable,
    ExtractionModelTraining,
    PageTabs,
  },

  data() {
    return ({
      activeTab: 'models',
    });
  },

  computed: {
    tabItems() {
      return [
        {
          title: this.$t('models.active'),
          bigNumber: this.totalActiveModelsDisplay,
          name: 'models',
        },
        {
          title: this.$t('models.training'),
          bigNumber: this.totalTrainingModelsDisplay,
          name: 'training',
        }
      ];
    },
  },

  watch: {
    activeTab(tab) {
      if (tab === 'training' && !this.datasetId) {
        this.$router.push({name: 'ExtractionTraining'});
      } else if (tab === 'models') {
        this.$router.push({name: 'Extraction'});
      }
    }
  },

  created() {
    if (this.datasetId || this.startView === 'training') {
      this.activeTab = 'training';
    }
  },

  props: {
    datasetId: {
      type: Number,
      default: 0,
    },

    startView: {
      type: String,
      default: 'models',
    },
  },
}
</script>

<style lang="scss" scoped>
.extraction-view {
  height: fit-content;
  &__full {
    padding: 0;
  }
}
</style>
