<template>
  <div class="page-container">
    <div class="page-number">
      p. {{ page.page_number }}
    </div>
    <div
      v-if="image"
      class="page background-spread clickable"
      :style="{
        width: `${pageWidth}px`,
        height: `${pageHeight}px`,
        'background-image': `url(${image})`,
      }"
      :class="{ selected }"
      @click="$emit('select', page)"
    />
    <div
      v-else
      class="page"
      :style="{
        'width': `${baseWidth}px`,
        'padding-top': `${baseWidth * 1.33}px`,
        'background-color': '#f5f5f5',
        'color': '#bdbdbd'
      }"
    >
      <v-icon
        v-if="loaded"
        class="error-icon"
      >
        fas fa-file-image
      </v-icon>
      <div
        v-if="loaded"
        class="error-message"
      >
        {{ $t('workflows.page_correction.image_not_found') }}
      </div>
    </div>
    <v-tooltip
      ref="popover"
      :disabled="!displayCategories"
      :open-on-focus="false"
      close-on-content-click
    >
      <template #activator="{ props }">
        <CustomSelect
          :id="`pages{page.id}`"
          v-bind="props"
          class="mt-4"
          placeholder="select"
          :disabled="!displayCategories"
          :selected="page.category_id"
          :items="categories"
          :max-width="150"
          :width="`${baseWidth}px`"
          @selected-changed="updatePageCategory"
        />
      </template>
      {{ categoryMap[page.category_id] }}
    </v-tooltip>
    <v-checkbox
      v-model="page.selected"
      class="page-checkbox"
      hide-details
    />
  </div>
</template>
<script>
import { ClassifyDocumentsAPI } from '@/API/classify/ClassifyDocumentsAPI';
import CustomSelect from '@/components/common/elements/Forms/CustomSelect';

export default {
  name: 'FilePage',

  components: {
    CustomSelect,
  },

  data() {
    return {
      baseWidth: 150,
      image: '',
      pageKey: 0,
      loaded: false,
      openMenu: false,
    }
  },

  computed: {
    ratio() {
      if (this.page && this.page.thumbnail_width && this.page.thumbnail_height) {
        return this.page.thumbnail_height / this.page.thumbnail_width;
      }
      return 1;
    },

    isPortrait() {
      return this.ratio > 1;
    },

    pageHeight() {
      const ratio = this.ratio;
      return this.baseWidth * ratio;
    },

    pageWidth() {
      const ratio = this.ratio;
      if (this.isPortrait) {
        return this.pageHeight / ratio;
      }
      return this.baseWidth;
    },
  },

  async mounted() {
    if (this.page) {
      await this.getImage();
    }
    this.loaded = true;
    const pageProbabilities = this.page.probabilities?.probabilities;
    if (pageProbabilities) {
      this.categories.sort((a, b) => {
        const probA = pageProbabilities[a.name] || 0;
        const probB = pageProbabilities[b.name] || 0;
        return probB - probA;
      });
    }
  },

  methods: {
    async getImage() {
      try {
        const data = await ClassifyDocumentsAPI.getPageThumbnail(
          this.$route.params.id,
          this.page.id,
        );
        this.image = URL.createObjectURL(new Blob([data]));
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },

    async updatePageCategory(category_id) {
      this.page.category_id = category_id;
      this.$refs.popover.isActive = false;
      this.openMenu = false;
    },
  },

props: {
  page: {
    type: Object,
    required: true,
  },
  selected: {
    type: Boolean,
    default: true,
  },
  categories: {
    type: Array,
    required: false,
    default: () => [],
  },
  categoryMap: {
    type: Object,
    required: false,
    default: () => {},
  },
  displayCategories: {
    type: Boolean,
    required: true,
  },
},

  emits: ['select'],
}
</script>
<style lang="scss" scoped>
  .error-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .error-message {
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 10px;
    line-height: 0.75rem;
  }

  .page-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    height: 100%;
    margin-bottom: 10px;
    position: relative;
  }

  .page {
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
    background-color: white;
    position: relative;
  }

  .page-number {
    text-align: left !important;
    width: 100%;
    margin-bottom: 2px;
    color: #747474;
    font-size: 12px;
  }

  .background-spread {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .selected {
    outline: solid 3px var(--v-primary-base);
    outline-offset: -3px;
  }

  .page-checkbox {
    position: absolute;
    top: -10px;
    right: 5px;
    line-height: 1rem;
  }

  ::v-deep .v-input .v-selection-control__wrapper {
    width: unset !important;

    .v-selection-control__input {
      width: unset !important;

      > .v-icon {
        color: var(--v-theme-grey) !important;
      }
    }

    .v-selection-control__input:hover::before {
      opacity: 0 !important;
    }
  }
</style>
