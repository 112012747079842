<template>
  <div
    v-if="email !== null && model !== null"
    class="page-padding py-7"
  >
    <div class="email-correction-view__header">
      <BackLink
        :text="'All emails review'"
        @click="() => $router.push({ name: 'Corrections', query: { activeTab: 'emailsReview' } })"
        show-text
      />
      <ClassificationValidation
        v-model="email.category_id"
        :submitting-review="submittingReview"
        :categories="model.categories"
        :default-discard-reason="$t('workflows.documents.file_corrupted')"
        @submit-review="submitCorrection"
        @submit-discard-reason="submitCorrection"
      />
    </div>
    <Email
      :email="email"
    />
  </div>
</template>

<script>

import { EmailsAPI } from '@/API/classify/EmailsAPI';
import BackLink from '@/components/common/elements/Navigation/BackLink.vue';
import Email from '@/components/common/elements/Emails/Email.vue';
import ClassificationValidation from '@/components/extract/views/Corrections/ClassificationValidation.vue';
import { ClassifyModelAPI } from '@/API/classify/ClassifyModelAPI';
import { displaySnackbarError, displaySnackbarSuccess } from '@/utils/SnackbarUtils';


export default {
    name: 'EmailClassificationReview',
    components: {
      Email,
      BackLink,
      ClassificationValidation,
    },

    data() {
      return {
        email: null,
        model: null,
      };
    },

    async created() {
      await this.fetchEmail();
      await this.fetchClassificationModel();
    },

    methods: {
      async fetchEmail() {
        this.email = await EmailsAPI.fetchEmail({ emailId: this.emailId });
      },

      async fetchClassificationModel() {
        this.model = await ClassifyModelAPI.getModel({ modelId: this.email.model_id });
      },

      async submitCorrection(discardReason) {
        try {
          const feedbackCategoryId = discardReason ? null : this.email.category_id;
          await EmailsAPI.updateEmail({ emailId: this.email.id, feedbackCategoryId, discardReason });
          const successMessage = discardReason ? this.$t('workflows.documents.review_discarded') : this.$t('workflows.documents.review_submitted');
          displaySnackbarSuccess(successMessage);
          this.$router.push({ name: 'Corrections', query: { activeTab: 'emailsReview' } });
        } catch (error) {
          displaySnackbarError(this.$t('corrections.correction_failed'));
        }
      }
    },

    props: {
      emailId: {
        type: Number,
        required: true,
      },
    },
}
</script>

<style lang="scss" scoped>

.email-correction-view__header {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 20px;
}

</style>